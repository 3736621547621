.Center {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: left;
}

.App {
  text-align: center; 
}